<template lang="pug">
  div.d-flex.flex-column
    TableV2(
      :headers="headers"
      :items="items.results"
      :pageCount="items.count"
      :currentPage="items.current"
      :querySearch="getDataReport"
      :isLoading="isLoading"
    )
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
  data () {
    return {
      headers: [
        { value: 'sailor_id',
          text: this.$i18n.t('tableHeaders.sailor_id'),
          sortable: false
        },
        { value: 'date_meeting',
          text: this.$i18n.t('tableHeaders.date_meeting'),
          sortable: false
        },
        { value: 'protokol_number',
          text: this.$i18n.t('tableHeaders.protokol_number'),
          sortable: false
        },
        { value: 'branch_office',
          text: this.$i18n.t('tableHeaders.branch_office'),
          sortable: false
        },
        { value: 'statement_number',
          text: this.$i18n.t('tableHeaders.statement_number'),
          sortable: false
        },
        { value: 'sailor_full_name',
          text: this.$i18n.t('tableHeaders.sailor_full_name'),
          sortable: false
        },
        { value: 'sailor_date_birth',
          text: this.$i18n.t('tableHeaders.sailor_date_birth'),
          sortable: false
        },
        { value: 'decision',
          text: this.$i18n.t('tableHeaders.decision'),
          sortable: false
        },
        { value: 'direction',
          text: this.$i18n.t('tableHeaders.direction'),
          sortable: false
        },
        { value: 'commissioner_type',
          text: this.$i18n.t('tableHeaders.commissioner_type'),
          sortable: false
        },
        { value: 'status_document',
          text: this.$i18n.t('tableHeaders.status_document'),
          sortable: false
        }
      ],
      isLoading: true
    }
  },
  computed: {
    ...mapState({
      items: state => state.report.certificateMembersSqc
    })
  },
  methods: {
    ...mapActions(['getReportCertificationMembersSQC']),
    async getDataReport (filter) {
      this.isLoading = true
      await this.getReportCertificationMembersSQC(filter)
      this.isLoading = false
    }
  }
}
</script>
